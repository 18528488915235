/* Styles for b and c version landing pages 
- Naming Conventions: serpent_case, to distinguish from Bulma-proper styles, which use dash-case.
- Note that this is compile automatically to a .css file of the same name by Jekyll.
*/

// =====VARIABLES=====

$button_grad_1: #32aede;
$button_grad_2: #3287de;
$button_grad_3: #2070d2;

$base_font_weight: 400;

// breakpoints
$iPhone5: 320px;
$tablet: 768px;
$desktop: 1024px;

// styles
.b_top {
	justify-content: space-between;
}

@mixin subtitles {
	font-family: 'Helvetica', 'Open-Sans', sans-serif;
	font-weight: $base_font_weight;
}
.b_subtitle {
	@include subtitles;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.c_subtitle {
	@include subtitles;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	margin-top: 1.5rem;
}

@media (max-height: 678px) {
	.c_subtitle {
		margin-top: 1rem;
	}
}

.b_burger {
	right: -20px;

	span {
		background-color: white !important;
	}

	:hover {
		background-color: transparent;
	}
}

.c_button {
	text-decoration: none;
	padding: 1.4em 2em !important;
	font-weight: 700 !important;
	font-size: 0.8em !important;
	background: linear-gradient(
		to bottom,
		$button_grad_1,
		$button_grad_2,
		$button_grad_3
	);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
	width: 100%;
	max-width: 320px;

	:hover {
		color: #3f43c3;
	}
}

.b_button {
	background: linear-gradient(to bottom, #03dafb, #2889ff, #2889ff);
	box-shadow: none;
	font-weight: 600 !important;
}

@media (max-width: 768px) {
	#navMenu {
		position: absolute;
		top: 8vh;
		right: -20px;
		background: linear-gradient(to bottom, #32c7de, #2070d2);
		border-radius: 4px;
	}
}

.blue_gradient_up {
	background: #005fbf;
}
.blue_gradient_down {
	background: linear-gradient(to bottom, #005fbf, $button_grad_3);
}
/* These are actually borders, now. Change the names if this wins the a/b.*/
.background_1 {
	/* background: linear-gradient(to bottom, #229fcf, #2070d2); */
	border-bottom: #ffffff69 solid 2px;
}
.background_2 {
	/* background: linear-gradient(#2070d2, #229fcf); */
	border-top: #ffffff00 solid 2px;
}
.background_3 {
	/* background: linear-gradient(#229fcf, #009ae9); */
	border-top: solid 2px #ffffff63;
}
.background_4 {
	/* background: linear-gradient(#009ae9, #229fcf); */
	border-top: solid 2px #ffffff63;
}
.background_5 {
	/* background: linear-gradient(#229fcf, #2070d2); */
	border-top: solid 2px #ffffff63;
	/* border-bottom: 4px solid #ffffff7a; */
}

.b_background {
	background: linear-gradient(to bottom, $button_grad_2, $button_grad_3);
}

#logo-img {
	/*This is necessary for Safari (and thus all of iOS). Otherwise the header div gets very large.*/
	height: 64px;
}

.c_title {
	margin-top: 10vh;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@media (max-height: 668px) {
	.c_title {
		margin-top: 6vh;
	}
}

.android_text {
	margin-top: 0em;
}

/* Mailchimp form styles */
#mc_embed_signup {
	.button {
		background: linear-gradient(
			to bottom,
			$button_grad_1,
			$button_grad_2,
			$button_grad_3
		);
	}

	form {
		text-align: center;
	}

	.mc-field-group input {
		color: #273033;
		max-width: 400px;
		margin: auto;
	}

	.mc-field-group label {
		font-weight: 400;
		text-shadow: 2px 2px 30px #0e1138;
	}
}

/* B version (long) */

.dropdown_label {
	font-weight: 600;
	font-size: 0.7em;
	color: rgba(255, 255, 255, 0.9);
}

@media (max-width: $iPhone5) {
	.dropdown_label {
		font-size: 0.6em;
		padding-right: 0.4em !important;
	}
}

.dropdown-content {
	/*background: #e5f7fff7;*/
	background: $button_grad_3;
	font-weight: $base_font_weight;
}

.dropdown_text {
	color: white;
	font-weight: $base_font_weight;
}

.dropdown_button {
	background: $button_grad_3;
	margin: 1em 0;
	font-weight: $base_font_weight;
}

@media (max-width: $iPhone5) {
	.dropdown_button {
		max-width: 82vw;
	}
}

.session_1_icon {
	margin: 1em 0 0 0;
}

@media (min-width: $tablet) {
	.session_1_icon {
		margin: 1em 0;
	}
}

.social_icon {
	font-size: 2em;
}

.down_chevron {
	position: absolute;
	bottom: 7vh;
	color: #ffffff;
	font-size: 1.5em;
	margin-left: calc(50% - (1.5rem + 15px));
	cursor: pointer;
}

@mixin percentOverlays {
	position: absolute;
	font-weight: 600;
}

.percent_overlay {
	@include percentOverlays;
	top: calc((35vw * 0.8 / 2));
	left: calc(35vw / 2.2);
}

@media (min-width: $tablet) {
	.percent_overlay {
		font-size: 2em !important;
		top: calc(60px + (35vw * 0.8) / 2);
		left: calc(35vw / 2.2);
	}
}

@media (min-width: $desktop) {
	.percent_overlay {
		left: calc(19vw / 2.5);
		top: calc((19vw * 0.75) * 0.55);
	}
}

.section_margins {
	padding: 5vh 30vw;
}

.guarantee_image {
	margin: 1em 0;
	border-radius: 20px;
}

.price {
	/* box-shadow: #0d5c7d 0px 0px 20px 1px; */
	/* padding: .5em;
      border-radius: 2px; */
	font-weight: $base_font_weight;
	/* background: linear-gradient(#03a9fd, #4fb4e6); */
}

.is_400 {
	font-weight: $base_font_weight;
}

/* for chevron rotation on dropdowns */

.rotate {
	transform: rotate(180deg);
}

.rotates {
	transition: all 400ms;
}

/* pricing checklist */
.fa-check {
	margin-right: 0.5em;
}

.pricing_list {
	text-align: left;
	padding: 1em 0;
	width: 110%;
}

.pricing_item {
	font-size: 0.95em;
	font-weight: 400;
}
